import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import styles from "../styles/socialnet.module.scss";

const SocialNet = (props) => {
	const { net, url } = props;
	const data = useStaticQuery(graphql`
		query {
			allFile(filter: {
				sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "socialnets" }
			}) {
				nodes {
					name
					publicURL
				}
			}
		}
	`);
	const icon = data.allFile.nodes.find(image => (image.name === net));

	return (
		<a className={props.className ? props.className : styles.socialnet} href={url} target={!props.sameTab && "_blank"}>
			<img src={icon.publicURL} alt={net} />
		</a>
	);
};

export default SocialNet;
