import React from "react";
import PropTypes from "prop-types";

import Header from "./header";
import Navigation from "./navigation";
import Footer from "./footer";
import Disclaimer from "./Disclaimer";

const Layout = ({ children }) => {
	return (
		<>
			<Header />
			<Navigation />
			<main>{children}</main>
			<Footer />
			<Disclaimer />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
