import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import SocialNet from "./socialnet";

import styles from "../styles/footer.module.scss";

const Footer = ({ siteTitle }) => {
	const data = useStaticQuery(graphql`
		query {
			file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "logos/logo critical-01.svg" }) {
				publicURL
			}
		}
	`);
	return (
		<footer className={styles.footer}>
			<div className={styles.footerContent}>
				<div className={styles.footerLinks}>
					<a className={styles.footerLogo} href="//criticaltechworks.com" target="_blank" rel="noopener noreferrer">
						<img className={styles.footerLogoImage} src={data.file.publicURL} alt="Critical Techworks" />
					</a>
					<div className={styles.footerSocialNets}>
						<SocialNet net="twitter" url="https://twitter.com/CTechWorks/" />
						<SocialNet net="facebook" url="https://www.facebook.com/CriticalTechWorks/" />
						<SocialNet net="linkedin" url="https://www.linkedin.com/company/criticaltechworks/" />
						<SocialNet net="instagram" url="https://www.instagram.com/criticaltechworks/" />
					</div>
					<a className={styles.footerContact} href="mailto:info@criticaltechworks.com">Contact</a>
				</div>
				<div className={styles.footerCopyright}>
					Critical TechWorks © 2019. All rights reserved.
					<Link to="/legal/terms">Terms & Privacy Policy</Link>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
