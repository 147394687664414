import React from "react";

import styles from "../styles/navigation.module.scss";

class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.positions = {
			top: '@(º_º)@',
			video: 'video',
			manifesto: 'manifesto',
			jobs: 'join us',
		};
		this.state = {
			position: 'top',
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', ((event) => { this.handleScroll(event) }));
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', ((event) => { this.handleScroll(event) }));
	}

	handleScroll(event) {
		let top = window.scrollY + 1,
			position = 'top';
		
		for (let id of Object.keys(this.positions)) {
			let section = document.getElementById(id);
			if (!section) { return; }
			if (top < section.offsetTop) {
				break;
			}
			position = id;
		}

		this.setState({
			position: position,
		});
	}

	render() {
		return (
			<div className={styles.container}>
				<div className={styles.navigation}>
					{
						Object.keys(this.positions).map((id) => {
							let title = this.positions[id];
							return <a className={this.state.position === id ? styles.item_current : styles.item} key={id} href={`#${id}`}>{title}</a>;
						})
					}
				</div>
			</div>
		);
	}
};

export default Navigation;
