import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import styles from "../styles/header.module.scss";

const Header = () => {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "logos/logo code.svg" }) {
				publicURL
			}
		}
	`);
	return (
		<header id="top" className={styles.header}>
			<img className={styles.logo} src={data.file.publicURL} alt="Free the Code Monkeys logo" />
		</header>
	);
};

export default Header;
