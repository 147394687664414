import React from "react";
import { Link } from "gatsby";

import styles from '../styles/disclaimer.module.scss';

class Disclaimer extends React.Component  {
	constructor(props) {
		super(props);
		this.state = {
			accepted: false,
		};
	}

	componentDidMount() {
		if (document.cookie.indexOf('cookiesAccepted=true') < 0) { return; }

		// cookies terms already previously accepted
		this.setState({
			accepted: true,
		});
	}

	createCookie(cookieName, cookieValue, hoursTillExpire) {
		let date = new Date();
		date.setTime(date.getTime() + (hoursTillExpire * 60 * 60 * 1000));
		document.cookie = `${cookieName}=${cookieValue}; expires=${date.toGMTString()}`;
	}

	handleAccept() {
		this.createCookie('cookiesAccepted', 'true', 24 * 30);
		this.setState({
			accepted: true,
		});
	}

	render() {
		if (this.state.accepted) { return null; }

		return (
			<div className={styles.disclaimer}>
				We use cookies to enhance your user experience and improve the quality of our site. If you continue browsing, we’ll assume that you consent to receiving all cookies.
				<Link className={styles.disclaimerLink} to="/legal/cookies">Find out more</Link>
				<button className={styles.disclaimerButton} onClick={() => this.handleAccept()}>Accept and continue</button>
			</div>
		);
	}
}

export default Disclaimer;
