import React from "react";

class Video extends React.Component {
	constructor(props) {
		super(props);
		this.started = false;
	}

	componentDidMount() {
		window.addEventListener('scroll', ((event) => { this.handleScroll(event) }));
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', ((event) => { this.handleScroll(event) }));
	}

	handleScroll(event) {
		if (this.started) { return; }
		
		let y = window.scrollY,
			iframe = document.getElementById('promovideo'),
			videoTop = iframe.scrollTop,
			videoBottom = videoTop + iframe.scrollHeight;

		if (y > videoTop && y < videoBottom) {
			this.started = true;
			iframe.src = 'https://www.youtube-nocookie.com/embed/WaFWQ_GwP9A?controls=0&modestbranding=1&loop=1&rel=0&autoplay=1';
		}
	}

	render() {
		return (
			<iframe id="promovideo" width="100%" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Promotion video"></iframe>
		);
	}
};

export default Video;
