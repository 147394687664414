import React from "react";
import PropTypes from "prop-types";

import styles from '../styles/job.module.scss';

class Job extends React.Component  {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	handleClick() {
		this.setState({
			expanded: !this.state.expanded,
		})
	};

	render() {
		const {jobid, title, html, url} = this.props;

		return (
			<div key={jobid} class={styles.job}>
				<div className={this.state.expanded ? styles.jobTitle_expanded : styles.jobTitle} onClick={() => this.handleClick()}>{title}</div>
				<div className={this.state.expanded ? styles.jobContent_expanded : styles.jobContent}>
					<div dangerouslySetInnerHTML={{ __html: html }} />
					<div className={styles.jobApply}>
						<a className={styles.jobApplyLink} href={url}>Apply</a>
					</div>
				</div>
			</div>
		);
	}
}

Job.propTypes = {
	jobid: PropTypes.string,
	title: PropTypes.string,
	html: PropTypes.string,
};

Job.defaultProps = {
	jobid: null,
	title: '',
	html: '',
};

export default Job;
